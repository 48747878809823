import {destroyCookie, parseCookies} from "nookies";
import {AxiosInstance} from "axios";
const axios = require('axios');

export function getAPIClient(ctx?: any) : AxiosInstance {
  const { 'nextauth.token': token } = parseCookies(ctx)

  const api = axios.create({
    baseURL: process.env.NEXT_PUBLIC_PUBLIC_PROXED_API,
    timeout: 9000
  })

  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  api.interceptors.response.use(
      (config: any) => config,
      (error: { response: { status: number; }; }) => {
          if (401 === error.response.status) {

              destroyCookie(undefined, 'nextauth.token');
              destroyCookie(undefined, 'user.role')

              // @ts-ignore
              window.location = '/login';

              return Promise.reject(error)
          } else {
              return Promise.reject(error);
          }
      }
  );

  return api;
}

export function getAPIServer() : AxiosInstance {
  return axios.create({
    baseURL: process.env.NEXT_PUBLIC_PUBLIC_API,
    timeout: 9000
  });
}