import axios from "axios";
import { api } from "./api";

type SignInRequestData = {
  email: string;
  password: string;
  type: string
}

export async function signInRequest(data: SignInRequestData) {
  return axios.post('/api/login', data);
}

export async function firstAccess(data: any) {
  return axios.post('/api/firstAccess', data);
}

export async function recoveryPassword(data: any) {
  return axios.post('/api/recoveryPassword', data);
}

export async function recoverUserInformation(type: string = 'boss') {
  const endpoint = type === 'boss' ? 'services-administrator' : 'rar';
  return api.get(`api/${endpoint}/my-profile`);
}

export async function updateUserInformation(data: any, role: string = 'boss') {
  const endpoint = role === 'boss' ? 'services-administrator' : 'rar';
  return api.put(`api/${endpoint}/my-profile`, data);
}

export async function updateUserPassword(data: any, role: string = 'boss') {
  const endpoint = role === 'boss' ? 'services-administrator' : 'rar';
  return api.put(`api/${endpoint}/my-profile/change-password`, data);
}

export async function finishedSubscription() {
  const endpoint= 'rar';
  return api.post(`api/${endpoint}/finished-subscription`);
}