import React, {FC} from 'react'

import {Container} from './styles'

import { useRouter } from 'next/router';
import { Header } from '../Header/Header';
import { Footer } from '../Footer/Footer';
import {classNames} from "primereact/utils";

interface Props {
    children: React.ReactNode
}
const Layout: FC<Props> = ({ children }) => {

    const router = useRouter();

    const renderTopAndBottom = (
        router.pathname === '/' ||
        router.pathname === '/auth/login-as-fellow' ||
        router.pathname === '/auth/login-as-manager' ||
        router.pathname === '/auth/recovery-password'
    );
 
    if(!renderTopAndBottom){
        return (
            <div
                className={classNames(
                    'min-h-screen',
                    'flex',
                    'flex-column',
                    router.pathname === '/manager/dashboard' ? 'surface-ground' : 'surface-section'
                )}
            >
                <Header/>
                <div className="sm:p-0 lg:p-5 flex flex-column flex-auto" style={{marginTop: '-8rem'}}>
                    <div
                        className={classNames(
                            'border-round',
                            'flex-auto',
                            router.pathname === '/manager/dashboard' ? 'surface-ground' : 'surface-section'
                        )}
                    >
                        <main>{children}</main>
                    </div>
                </div>
                <Footer/>
            </div>
    )
    } else {
        return (
            <Container>
                <main>{children}</main>
            </Container>
        )
    }
}

export {Layout}
