import React, {FC, useContext, useRef, useState} from 'react'
import Router from 'next/router'
import { Menubar } from 'primereact/menubar';
import { AuthContext } from '../../.contexts/AuthContext';
import {Ripple} from "primereact/ripple";
import {StyleClass} from "primereact/styleclass";
import {BreadCrumb} from "primereact/breadcrumb";
import LogoCustomerAlpha from './../../public/logo-alfa.svg'
import Image from "next/image";


const Header: FC = () => {
    const { signOut, role, user } = useContext(AuthContext)
    const [menuVisible, setMenuVisible] = useState(false);
    const refShowHiddenMenu = useRef(null);

    const MENU_ITEM_HOME = {
        icon: 'pi pi-home',
        command: () => Router.push("/home")
    }
    const items = [
        { label: 'Página Inicial', },
    ];

    const MENU_ITEMS_BOSS = [
        {
            label:'Sobre o Programa',
            icon:'pi pi-inbox',
            items: [
                {
                    label: 'Informações Principais',
                    icon: 'pi pi-fw pi-inbox',
                    command: () => Router.push("/manager/program-informations")
                },
                {
                    label: 'Gerenciamento de Hospitais',
                    icon: 'pi pi-fw pi-heart-fill',
                    command: () => Router.push("/manager/hospitals")
                },
                {
                    label: 'Gerenciamento de Membros',
                    icon: 'pi pi-fw pi-users',
                    command: () => Router.push("/manager/members")
                },
                {
                    label: 'Gerenciamento de Fellows',
                    icon: 'pi pi-fw pi-users',
                    command: () => Router.push("/manager/fellows")
                },
                {
                    label: 'Gerenciamento de Atividades',
                    icon: 'pi pi-list-check',
                    command: () => Router.push("/manager/surgeries")
                }

            ],
        },
        {
            label: 'Painel de Análises',
            icon: 'pi pi-chart-bar',
            command: () => Router.push("/manager/dashboard")
        },
        {
            label: 'Configurações',
            icon: 'pi pi-cog',
            items: [
                {
                    label: 'Atualizar Dados Pessoais',
                    icon: 'pi pi-user',
                    command: () => Router.push("/settings/personal-information")
                },
                {
                    label: 'Alterar Senha',
                    icon: 'pi pi-lock',
                    command: () => Router.push("/settings/change-password")
                }
            ]
        },
        {
            label: 'Desconectar',
            icon: 'pi pi-sign-out',
            command: () => signOut()
        }
    ];

    const MENU_ITEMS_RAR = [
        {
            label:'Minha Conta',
            icon:'pi pi-inbox',
            command: () => Router.push("/rar"),
        },
        {
            label: 'Configurações',
            icon: 'pi pi-cog',
            items: [
                {
                    label: 'Atualizar Dados Pessoais',
                    icon: 'pi pi-user',
                    command: () => Router.push("/settings/personal-information")
                },
                {
                    label: 'Alterar Senha',
                    icon: 'pi pi-lock',
                    command: () => Router.push("/settings/change-password")
                }
            ]
        },
        {
            label: 'Desconectar',
            icon: 'pi pi-sign-out',
            command: () => signOut()
        }
    ];

    return (
        <>
            <div className="bg-indigo-500" style={{height: '250px'}}>
                <div
                    className="py-3 px-5 flex align-items-center justify-content-between relative lg:static"
                    style={{minHeight: '80px'}}>

                    <Image src={LogoCustomerAlpha} width="102" alt="Image" height="40" className="mr-0 lg:mr-6"/>

                    <StyleClass nodeRef={refShowHiddenMenu} selector="@next" enterClassName="hidden" leaveToClassName="hidden" hideOnOutsideClick>
                        <a ref={refShowHiddenMenu} className="p-ripple cursor-pointer block lg:hidden text-indigo-50" >
                            <i className="pi pi-bars text-4xl"></i>
                            <Ripple />
                        </a>
                    </StyleClass>

                    <div
                        className="animation-duration-500 align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full bg-indigo-500 left-0 top-100 z-1">
                        {/* Items To The Left */}
                        <Menubar
                            className={`list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row ${menuVisible ? 'block' : 'block'}`}
                            model={role?.role_name === 'boss' ? MENU_ITEMS_BOSS : MENU_ITEMS_RAR}

                        />
                        {/* Items To The Right */}
                        <ul className="list-none p-0 m-0 flex lg:align-itex-colums-center flemn lg:flex-row border-top-1 border-indigo-400 lg:border-top-none">
                            <li className="w-full border-top-1 border-indigo-400 lg:border-top-none">
                                <a className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150">
                                    <img src="/do-utilizador.png"
                                         className="mr-3 lg:mr-0" style={{width: '32px', height: '32px'}}
                                         alt="avatar-f-1"/>
                                    <div className="block ml-3">
                                        <div className="text-indigo-50 font-medium">{user?.nome}</div>
                                        <span
                                            className="text-indigo-100 font-medium text-sm">{user?.perfil}</span>
                                    </div>
                                    <Ripple/>
                                </a>
                            </li>
                        </ul>
                    </div>

                </div>

                <BreadCrumb
                    model={items} home={MENU_ITEM_HOME}
                    className="list-none py-3 px-5 m-0 flex align-items-center font-medium overflow-x-auto border-top-1 border-indigo-600"
                />

            </div>
        </>
    )
}
export {Header}
