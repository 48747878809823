import React, {FC} from 'react'
import {DevelopedBy, Content} from './styles'
import Image from "next/image";
import LogoCustomerAlpha from './../../public/logo-alfa.svg'

const Footer: FC = () => {
    return (
        <>
            <Content className="surface-section px-4 md:px-6 lg:px-8">
                    <div className="py-6 flex flex-column sm:flex-row sm:align-items-center justify-content-between">
                        <div>
                            <Image src={LogoCustomerAlpha} alt="Image" width="179" height="70"/>
                            <div className="mt-2 line-height-3 text-white"><span>&copy;  SBC - Sociedade Brasileira de Coluna - Todos os direitos reservados</span></div>
                        </div>
                        <div className="mt-3 sm:mt-0">
                            <a className="cursor-pointer text-white transition-colors transition-duration-150 hover:text-500"
                               href={"https://www.instagram.com/sociedadebrasileiradecoluna/"} target={"_Blank"}>
                                <i className="pi pi-instagram text-xl"></i>
                            </a>
                            <a className="cursor-pointer text-white ml-3 transition-colors transition-duration-150 hover:text-500"
                                href={"https://www.facebook.com/sociedadebrasileiradecoluna/?locale=pt_BR"} target={"_blank"}>
                                <i className="pi pi-facebook text-xl"></i>
                            </a>
                            <a className="cursor-pointer text-white ml-3 transition-colors transition-duration-150 hover:text-500"
                                href={"https://www.youtube.com/channel/UCTb3wwy0iQ282Luz4b0izkw"} target={"_Blank"}>
                                <i className="pi pi-youtube text-xl"></i>
                            </a>
                        </div>
                    </div>
            </Content>
            <DevelopedBy className="surface-section px-4 md:px-6 lg:px-8 py-3">
                <div className="flex flex-column sm:flex-row sm:align-items-center justify-content-end">
                    <span className="text-white-alpha-50">Desenvolvido Por: <a className="text-white-alpha-50" href={"https://limhub.com.br"}><b>LIMHUB</b></a>
                    </span>
                </div>
            </DevelopedBy>
        </>
)
}

export {
    Footer
}
