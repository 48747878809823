import { createContext, ReactNode, useEffect, useState } from "react";
import { setCookie, parseCookies, destroyCookie } from 'nookies'
import Router from 'next/router'

import { recoverUserInformation, signInRequest } from "../services/authService";
import { api } from "../services/api";

import {AxiosResponse} from "axios";

type User = {
  id: string;
  name: string;
  perfil: string;
  nome: string;
  email: string;
  especialidade: string;
  situacao: string;
  foto: string;
  genero: string;
  celular_whatsapp: string;
  celular_2: string;
  telefone_fixo: string;
  endereco: string;
  dt_nascimento: string;
  mini_curriculum: string;
  curriculum_lates: string;
  maior_titulo_academico: string;
}

type Role = {
  role_name: string
}

type SignInData = {
  email: string;
  password: string;
  type: string;
}

interface AuthContextProviderProps {
  children: ReactNode;
}

type AuthContextType = {
  isAuthenticated: boolean;
  user: User | null;
  role: Role | null;
  signIn: (data: SignInData) => Promise<void>
  signOut: () => void,
  updateUserInformations: (role:string) => void
}

export const AuthContext = createContext({} as AuthContextType)

export function AuthProvider({ children }: AuthContextProviderProps) {
  const [user, setUser] = useState<User | null>(null)
  const [role, setRole] = useState<Role | null>(null)

  const isAuthenticated = !!user;

  useEffect(() => {
    const { 'nextauth.token': token } = parseCookies()
    const { 'user.role': type } = parseCookies()

    if (token) {

      recoverUserInformation(type).then(response => {
        setUser(response.data.data as User);
        setRole({role_name: type})
      })

    }
  }, [])

  async function signIn({ email, password, type }: SignInData) {
    try{

      const responseSignRequest: AxiosResponse<any, any> = await signInRequest({email,password, type });
      const { access_token } = responseSignRequest.data;

      api.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;

      setCookie(undefined, 'nextauth.token', access_token, {
        maxAge: 60 * 60, // 1 hour
      });

      setCookie(undefined, 'user.role', type, {
        maxAge: 60 * 60, // 1 hour
      });

      const responseUserInformation: AxiosResponse<any, any> = await recoverUserInformation(type)

      setUser(responseUserInformation.data.data);
      setRole({role_name: type} as Role);

      if(type === 'resident')
        await Router.push('/rar');
      else
        await Router.push('/manager/program-informations');

    }catch (error: any){

      if(error.response)
        return Promise.reject(new Error(error.response.data.message));
      else
        return Promise.reject(new Error(error.message))

    }
  }

  async function updateUserInformations(role:string) {
    recoverUserInformation(role).then(response => {
      setUser(response.data.data as User);
    })
  }

  async function signOut() {

    destroyCookie(undefined, 'nextauth.token');
    destroyCookie(undefined, 'user.role')

    Router.push('/login');
    
  }

  return (
    <AuthContext.Provider value={{ user, role, isAuthenticated, signIn, signOut, updateUserInformations }}>
      {children}
    </AuthContext.Provider>
  )
}