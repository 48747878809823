import { createContext, ReactNode, useCallback, useState } from "react";
import React, { useRef } from 'react';
import { Toast } from 'primereact/toast';

interface ToastDataContextProviderProps {
  children: ReactNode;
}

interface ToastDataInterface{
  severity: string | null,
  summary: string | null,
  detail: string | null ,
  life: number | null
}

interface ToastContextProviderPropsData{
  showToast:  (data: ToastDataInterface) => Promise<void>,
}

export const ToastContext = createContext<ToastContextProviderPropsData>(
    {} as ToastContextProviderPropsData
);

export function ToastContextProvider({ children }: ToastDataContextProviderProps) {
  const toast = useRef<any>(null); // Adjust the type according to the ref

  const showToast = async (data: ToastDataInterface) => {
    if (toast.current) {
      toast.current.show({
        severity: data.severity || 'info',
        summary: data.summary || '',
        detail: data.detail || '',
        life: data.life || 3000,
      });
    }
  };

  return (
      <ToastContext.Provider value={{ showToast }}>
        {children}
        <Toast ref={toast} />
      </ToastContext.Provider>
  );
}
