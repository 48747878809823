import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import '../styles/theme.css'

import {Layout} from "../shared/Layout/Layout";

import type { AppProps } from 'next/app'

import { ToastContextProvider } from "../.contexts/ToastContext";
import { AuthProvider } from '../.contexts/AuthContext'

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <ToastContextProvider>
      <AuthProvider>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </AuthProvider>
    </ToastContextProvider>
  )
}

export default MyApp
