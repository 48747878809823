import styled from 'styled-components';

export const Content = styled.div`
  background-color: var(--primary-color);
  margin-top:100px;
`;

export const DevelopedBy = styled.div`
    background-color: #1f2a6a;
`;
export const Container = styled.div`
  width: 1140px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h3{color: white;}
  ul{list-style:none}
  li{margin-bottom:15px}
  li a{
    color: var(--primary-color-text)
  }

  @media screen and (max-width: 1140px) {
    width: 90%;
  }
`